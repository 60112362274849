<template>
  <div class="list row">
  
    <div class="col-md-12" v-if="currentConcurs" >


     <div class="container-fluid">

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 class="h3 mb-2 text-gray-800">{{ currentConcurs.concurs.nume }} {{ currentConcurs.concurs.data }} 
          </h1>
          <router-link target="_blank" :to="{ name:'vezi-concurs', params: { id: $route.params.idConcurs} }" class="btn btn-primary btn-icon-split shadow-sm margin-right-btn " >
            <span class="icon text-white-50">
                <i class="fas fa-link"></i>
            </span>
            <span class="text">Link Public</span>
          </router-link>
        </div>

        <echipe :concurs="currentConcurs" />

        <hr />
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h2 class="h3 text-gray-800">Optiuni Concurs</h2>
            <div>
              <a class="btn btn-primary btn-icon-split shadow-sm margin-right-btn " @click="editeazaCurs = !editeazaCurs">
                  <span class="icon text-white-50">
                    <i class="fas fa-edit"></i>
                </span>
                <span class="text">Editeaza</span>
              </a>
              <a class="btn btn-danger btn-icon-split shadow-sm" @click="deleteConcurs()">
                <span class="icon text-white-50">
                    <i class="fas fa-trash"></i>
                </span>
                <span class="text">Sterge</span>
              </a>
            </div>
        </div>

        <div class="card shadow mb-4"  v-if="editeazaCurs">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Actualizare date concurs</h6>
          </div>
          <div class="card-body">
            <form class="marginBottomForm">
              <div class="form-group">
                <label for="title">Title</label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  v-model="currentConcurs.concurs.nume"
                />
              </div>

              <div class="form-group">
                <label for="editia">Editia</label>
                <input
                  type="text"
                  class="form-control"
                  id="editia"
                  v-model="currentConcurs.concurs.editia"
                />
              </div>

              <div class="form-group">
                <label for="data">Data</label>
                <input
                  type="text"
                  class="form-control"
                  id="data"
                  v-model="currentConcurs.concurs.data"
                />
              </div>

              <div class="form-group">
                <label for="locatia">Locatie</label>
                <input
                  type="text"
                  class="form-control"
                  id="locatia"
                  v-model="currentConcurs.concurs.locatie"
                />
              </div>

              <div class="flex-wrap d-flex justify-content-between">
              <button type="submit" class="btn btn-success">Actualizeaza</button>
              <button @click="editeazaCurs = !editeazaCurs" class="btn btn-warning">Anulare</button>
              </div>
            </form> 
          </div>
        </div>
    
      </div>
    </div>
  </div>
</template>
<script>
import PescariDataService from "../services/PescariDataService";
import Echipe from "./EchipeList";

export default {
  name: "concursuri-list",
  components: { 
    Echipe,
  },
  data() {
    return {
      currentConcurs: null,
      editeazaCurs: false,
    };
  },
  methods: {
    deleteConcurs() {
      let result = confirm("Sigur vrei sa stergi concursul?");
      if(result){
        PescariDataService.deleteConcurs(this.currentConcurs.id)
          .then(() => {
            this.$router.push({ name: 'concursuri' })
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
  mounted() {
    PescariDataService.getConcurs(this.$route.params.idConcurs).then(
      qSnap => {
        this.currentConcurs = qSnap;
        this.currentConcurs.id = this.$route.params.idConcurs;
      }
    );
  },
};
</script>
<style scoped>
  .marginBottomForm{
    margin-bottom:60px;
  }
</style>