<template>

  <hr />
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h2 class="h3 mb-0 text-gray-800">Echipe</h2>
  </div>

  <div class="row"  v-if="echipe.length" >

      <div class="col-xl-6 col-md-6 mb-4 cursor-pointer-default" 
        v-for="(echipa, index) in echipe"
        :key="index" 
        @click="goToEchipa(echipa.id)"
      >
          <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                               Sector: {{ echipa.echipa.sector }}{{echipa.echipa.stand}}</div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">{{ echipa.echipa.nume }}</div>
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-users fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

       <div class="col-xl-6 col-md-6 mb-4 cursor-pointer-default" @click="adaugaEchipa = !adaugaEchipa" >
          <div class="card border-left-success shadow h-100 py-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Echipa Noua</div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">Adauga</div>

                      </div>
                      <div class="col-auto">
                          <i class="fas fa-plus fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </div>
  <div v-else>
      <div class="card shadow mb-4">
        <div class="card-body allow-overflow">
          <div class="fa-1x text-center text-primary mb-4 mt-4">
            Se incarca &nbsp; <i class="fas fa-fish fa-spin"></i>
          </div>
        </div>
      </div>
    </div>





  <div class="wrap_form_group form-group" v-if="adaugaEchipa">


    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Adauga Echipa</h6>
        </div>
        <div class="card-body">

          <p><input
              type="text"
              class="form-control"
              id="nume_echipa"
              required
              v-model="echipa.nume"
              name="nume_echipa"
              placeholder="Nume Echipa"
            /></p>

            <p><input
              type="text"
              class="form-control"
              id="sector_echipa"
              required
              v-model="echipa.sector"
              name="sector_echipa"
              placeholder="Sector"
            /></p>

            <p><input
              type="text"
              class="form-control"
              id="stand_echipa"
              required
              v-model="echipa.stand"
              name="stand_echipa"
              placeholder="Stand"
            /></p>

            <div class="d-sm-flex align-items-center justify-content-between mb-4">
              <button @click="addEchipa" class="btn btn-success">Adauga</button>
              <button @click="adaugaEchipa = !adaugaEchipa" class="btn btn-warning">Anuleaza</button>
            </div>
        </div>
    </div>

  </div>



  <ul class="list-group card shadow mb-4" v-if="echipe.length && 1==0">
    <li
      class="list-group-item"
      :class="{ active: index == currentIndex }"
      v-for="(echipa, index) in echipe"
      :key="index" 
     
    >
      <div class="clickableName"  @click="adaugaDate(echipa.id)">
        {{ echipa.echipa.nume }} (Sector: {{ echipa.echipa.sector }}{{echipa.echipa.stand}})
      </div>

      <div>

    
        <span class="btn btn-success btn-icon-split shadow-sm margin-right-btn " @click="adaugaDate(echipa.id)">
          <span class="icon text-white-50">
            <i class="fas fa-plus"></i>
          </span>
          <span class="text">Adauga Date</span>
        </span>
        
        <span class="btn btn-primary btn-icon-split shadow-sm " @click="modifica(echipa.id, echipa.echipa.nume, echipa.echipa.sector, echipa.echipa.stand)">
          <span class="icon text-white-50">
            <i class="fas fa-edit"></i>
          </span>
          <span class="text">Edit</span>
        </span>


        <!-- <span class="adauga_date_wrap" @click="modifica(echipa.id, echipa.echipa.nume, echipa.echipa.sector, echipa.echipa.stand)">Editeaza</span> -->
      </div>

        <div class="full_row" v-if="echipaEditata == echipa.id">
       
            <p><input
              type="text"
              class="form-control"
              id="nume_echipa"
              required
              v-model="echipaEditataNume"
              name="nume_echipa"
              placeholder="Nume Echipa"
            /></p>

            <p><input
              type="text"
              class="form-control"
              id="sector_echipa"
              required
              v-model="echipaEditataSector"
              name="sector_echipa"
              placeholder="Sector"
            /></p>

            <p><input
              type="text"
              class="form-control"
              id="stand_echipa"
              required
              v-model="echipaEditataStand"
              name="stand_echipa"
              placeholder="Stand"
            /></p>

            <button @click="modificaEchipa" class="btn btn-success">Modifica</button>

            <span @click="deleteEchipa(echipa.id)" class="btn btn-danger delete_echipa">Sterge</span>
         
        </div>

      <div class="full_row" v-if="echipaSelectata == echipa.id">
        <input 
          type="number"
          class="form-control"
          pattern="\d+((\.|,)\d+)?"
          placeholder="Greutate pește"
          v-model="greutate"
        >
        
          <span class="btn btn-success btn-icon-split shadow-sm margin-right-btn "  @click="adaugaGreutate(echipa.id)">
            <span class="icon text-white-50">
              <i class="fas fa-plus"></i>
            </span>
            <span class="text">Adauga</span>
          </span>

          <span class="adauga_status" :class="{ easeOut: easeGreutateSuccess }" v-if="showGreutateSuccess">Am adaugat greutatea peștelui.</span>

      </div>
    </li>
  </ul>



</template>
<script>
import PescariDataService from "../services/PescariDataService";

export default {
  name: "Echipe",
  props: ["concurs"],
  data() {
    return {
      showGreutateSuccess: false,
      easeGreutateSuccess: false,
      adaugaEchipa: false,
      currentConcurs: null,
      currentConcursID: null,
      currentIndex: -1,
      echipaSelectata: null,
      echipaEditata: null,
      echipaEditataNume: null,
      echipaEditataSector: null,
      echipaEditataStand: null,
      greutate: null,
      echipe: [],
      adaugaGreutatePending: false,
      echipa:{
        nume: "",
        concursID: "",
        sector: "",
        stand: "",
      }
    };
  },
  watch: {
    concurs: function(concurs) {
      this.currentConcurs = { ...concurs };
      this.refreshEchipe();
    },
  },
  methods:{
    goToEchipa(idEchipa){
      this.$router.push({ name: 'edit-echipa', params: { idConcurs: this.$route.params.idConcurs, idEchipa: idEchipa } })

    },
    adaugaGreutate(idEchipa){
      if(this.adaugaGreutatePending) return false;
      this.adaugaGreutatePending = true;
      this.showGreutateSuccess = false;

       PescariDataService.addGreutateEchipa( this.currentConcursID, idEchipa, this.greutate)
        .then(() => {
          this.greutate = null;
          this.showGreutateSuccess = true;
          this.adaugaGreutatePending = false;
          setTimeout( () => { 
            this.easeGreutateSuccess = true;
          }, 2500);
          setTimeout( () => {
            this.showGreutateSuccess = false;  
            this.easeGreutateSuccess = false;
          },3000);
        })
        .catch(e => {
          console.log(e);
        });
    },
 
    addEchipa(){
      var data = {
        nume: this.echipa.nume,
        sector: this.echipa.sector,
        stand: this.echipa.stand,
      };
      PescariDataService.addEchipa( this.currentConcursID, data)
        .then(() => {
          this.refreshEchipe();
          this.echipa.nume = "";
          this.echipa.sector = "";
          this.echipa.stand = "";
        })
        .catch(e => {
          console.log(e);
        });
    }, 
    refreshEchipe(){
      this.currentConcursID = this.concurs.id;
      PescariDataService.getEchipe( this.currentConcursID ).then( qSnap => {
        this.echipe = [...qSnap];
        this.echipe.sort((a, b) => (a.echipa.stand < b.echipa.stand) ? 1 : -1)
        this.echipe.sort((a, b) => (a.echipa.sector > b.echipa.sector) ? 1 : -1)
      });      

      this.echipaSelectata = null;
    }
  },
  mounted() {
    this.currentConcurs = { ...this.concurs }
    this.refreshEchipe();
  },
};
</script>
<style scoped>
.list-group{
  margin-top:0px;
  margin-bottom:45px;
}
.clickableName:hover{
  color:#363636;
}
.list-group-item .clickableName{
  cursor:pointer;
  flex-shrink: 0;
  flex-grow: 1;
  transition:all 0.4s ease;
}
.list-group-item{
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.delete_echipa{
  text-align:right;
  /* color:red; */
  cursor:pointer;
  margin-left:10px;
}
.list-group-item > div a{
  white-space:nowrap;
}
p{
  margin-top:30px;
}
.full_row{
  width:100%;
  padding:20px;
  border-radius:10px;
  background-color:#f5f5f5;
  margin-top:10px;
}
.full_row p:first-child{
  margin-top:0px;
}
.full_row input{
  border:1px solid #e9e9e9;
  width:450px;
  max-width:100%;
  display:inline-block;
  margin-right:15px;
}
.full_row a{
  display:inline-block;
}
.adauga_date_wrap{
 color:#007bff; 
 margin-left:10px;
 cursor:pointer;
}
h2{
  font-size:1.5rem;
}
.adauga_status{
  transition:all 0.4s ease;
}
.adauga_status.easeOut{
  opacity:0;
}
</style>